import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  padding: 8px 22px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  font-family: 'Inter-Regular';
  transition: all 0.5s ease;
  -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    display: inline-block;
    text-align: center;
  outline: none;

  ${(props) =>
          props.variant === 'outlined'
                  ? css`
                    background-color: transparent;
                    border: 2px solid #fff;
                    color: #fff;
                  `
                  : css`
                    background-color: #00a3ff;
                    border: 2px solid #00a3ff;
                    color: white;
                  `}

  &:hover {
    ${(props) =>
            props.variant === 'outlined'
                    ? css`
                      background-color: #00a3ff;
                      color: white;
                      border-color: #00a3ff;
                      box-shadow: 0 0 100px 0 #00a3ff;
                    `
                    : css`
                      background-color: transparent;
                      border-color: #fff;
                      box-shadow: 0 0 100px 0 #fff;
                    `}
  }

  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

const ReusableButton = ({ onClick, label, variant = 'default', linkTo, openPdfInNewTab, mailto, as, href }) => {
    const handleClick = () => {
        if (mailto) {
            window.location.href = `mailto:{mailto}`;
        } else if (openPdfInNewTab) {
            window.open(linkTo, '_blank');
        } else if (onClick) {
            onClick();
        }
    };

    const buttonProps = {
        onClick: handleClick,
        variant: variant,
        mailto: mailto,
        as: as,
        href: href,
    };

    return <StyledButton {...buttonProps}>{label}</StyledButton>;
};

export default ReusableButton;




