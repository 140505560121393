import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 100px; /* Значение по умолчанию для всех сторон */
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '100px')}; /* Если задан paddingBottom, то используем его */
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '0')}; /* Если задан paddingBottom, то используем его */
  
    @media (min-width: 1600px) {
        padding: 15%; /* Значение по умолчанию для всех сторон */
        padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '100px')}; /* Если задан paddingBottom, то используем его */
        padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '0')}; /* Если задан paddingBottom, то используем его */
    }
  
  @media (max-width: 992px) {
    padding: 20px; /* Значение по умолчанию для всех сторон */
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '40px')}; /* Если задан paddingBottom, то используем его */
    padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '0')}; /* Если задан paddingBottom, то используем его */
  }
`;

const SectionWrapper = ({ children, id, paddingBottom, paddingTop }) => {
    return <Wrapper id={id} paddingBottom={paddingBottom} paddingTop={paddingTop}>{children}</Wrapper>;
};

export default SectionWrapper;
