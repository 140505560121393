import React from 'react';
import SectionWrapper from '../../UI/SectionWrapper';
import TitleSection from "../../UI/TitleSection";
import styled from "styled-components";
import Card from "../../UI/StackCard";
import HtmlLogo from "../../../assets/StackLogo/html-5-logo.svg";
import CssLogo from "../../../assets/StackLogo/css-3-logo.svg";
import JsLogo from "../../../assets/StackLogo/js-logo.svg";
import ReactLogo from "../../../assets/StackLogo/react-logo.svg";
import ReduxLogo from "../../../assets/StackLogo/redux-logo.svg";
import NodeJsLogo from "../../../assets/StackLogo/node-js-logo.svg";
import ExpressJsLogo from "../../../assets/StackLogo/express-js-logo.svg";
import TypesriptLogo from "../../../assets/StackLogo/typescript-logo.svg";
import WordpressLogo from "../../../assets/StackLogo/wordpress-logo.svg";
import SassLogo from "../../../assets/StackLogo/sass-logo.svg"
import BootstrapLogo from "../../../assets/StackLogo/bootstrap-logo.svg"
import MUILogo from "../../../assets/StackLogo/mui-logo.svg"
import StyledComponentsLogo from "../../../assets/StackLogo/styled-components-logo.svg"
import GitLogo from "../../../assets/StackLogo/git-logo.svg"
import NextLogo from "../../../assets/StackLogo/nexn-js-logo.svg"

const CardWrapper = styled.div`
  width: 75%;
  margin-left: auto; /* Прижатие к правому краю */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  
  @media (max-width: 992px) {
    width: 100%;
    justify-content: space-between;
  }
`;


const Stack = () => {

    const items = [
        {
            title: 'HTML',
            img: HtmlLogo,
        },
        {
            title: 'CSS',
            img: CssLogo,
        },
        {
            title: 'JavaScript',
            img: JsLogo,
        },
        {
            title: 'React',
            img: ReactLogo,
        },
        {
          title: 'Next',
            img: NextLogo,
        },
        {
            title: 'Redux',
            img: ReduxLogo,
        },
        {
            title: 'TypeScript',
            img: TypesriptLogo,
        },
        {
            title: 'Node.js',
            img: NodeJsLogo,
        },
        {
            title: 'express',
            img: ExpressJsLogo,
        },
        {
            title: 'GitHub',
            img: GitLogo,
        },
        {
            title: 'Wordpress',
            img: WordpressLogo,
        },
        {
            title: 'Sass',
            img: SassLogo,
        },
        {
            title: 'Styled Components',
            img: StyledComponentsLogo,
        },
        {
            title: 'Bootsrap',
            img: BootstrapLogo,
        },
        {
            title: 'MUI',
            img: MUILogo,
        },

    ];

    return (
        <SectionWrapper>
            <TitleSection title="Stack"/>
            <CardWrapper>
                {items.map((item, index) => (
                    <Card img={item.img} key={index} title={item.title} />
                ))}
            </CardWrapper>
        </SectionWrapper>
    );
};

export default Stack;