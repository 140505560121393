import React from 'react';
import FirstScreen from "./FirstScreen";
import JobExperience from "./JobExpirience";
import Stack from "./Stack";
import styled from "styled-components";
import Portfolio from "./Portfolio";

const Container = styled.div`
  //position: relative;
  //z-index: 0; /* Устанавливаем индекс для размещения под контентом */
  //
  //&::before,
  //&::after {
  //  content: '';
  //  position: absolute;
  //  width: 300px; /* Размер элипсов можно настроить по вашему усмотрению */
  //  height: 300px;
  //  background-color: #7407FF;
  //  border-radius: 50%; /* Превращаем прямоугольник в элипс */
  //  filter: blur(500px); /* Добавляем размытие для свечения */
  //  z-index: -1; /* Помещаем псевдоэлементы под содержимым */
  //}
  //
  //&::before {
  //  top: -50px;
  //  left: -50px;
  //}
  //
  //&::after {
  //  bottom: -50px;
  //  right: -50px;
  //}
  
`;

const MainPage = () => {
    return (
        <Container>
            <FirstScreen/>
            <JobExperience/>
            <Stack/>
            <Portfolio/>
        </Container>
    );
};

export default MainPage;