import React from 'react';
import RoundIconButton from "../../UI/RoundButton";
import SectionWrapper from "../../UI/SectionWrapper";
import styled from "styled-components";
import ReusableButton from "../../UI/StyledButton";
import QRImage from '../../../assets/Images/QR.svg';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  
    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const FirstBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  gap: 20px;
`;

const SecondBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  
    @media (max-width: 992px) {
        height: 400px;
      align-items: center;
    }
`;

const CardWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > * {
    position: absolute;
    width: 150px;
    height: 100px;
    background-color: #ccc;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    
    @media (max-width: 992px) {
        width: 100px;
        height: 70px;
    }
  }

  & > :nth-child(1) { /* Первая карточка */
    background-image: url('https://i.ibb.co/sRdymPX/Space-2.jpg');
    top: -200px;
    background-size: cover;
    background-position: center;
    left: -200px;
    
    @media (max-width: 992px) {
        top: -150px;
        left: -125px;
    }
  }

  & > :nth-child(2) { /* Вторая карточка */
    background-image: url('https://i.ibb.co/9hpb51h/2.jpg');
    top: -200px;
    background-size: cover;
    background-position: center;
    right: -200px;
    
    @media (max-width: 992px) {
        top: -150px;
        right: -125px;
    }

  }

  & > :nth-child(3) { /* Третья карточка */
    background-image: url('https://i.ibb.co/M647x8k/1.jpg');
    top: -140px;
    background-size: cover;
    background-position: center;
    right: -250px;
    
    @media (max-width: 992px) {
        top: -100px;
        right: -175px;
    }
  }

  & > :nth-child(4) { /* Четвертая карточка */
    bottom: -200px;
    background-image: url('https://i.ibb.co/27Z6wNy/2024-04-28-14-03-02.png');
    right: -200px;
    background-size: cover;
    background-position: center;
    @media (max-width: 992px) {
        bottom: -150px;
        right: -125px;
    }
  }

  & > :nth-child(5) { /* Пятая карточка */
    bottom: -140px;
    background-image: url('https://i.ibb.co/4MYfTHH/2024-04-28-14-02-32.png');
    background-size: cover;
    background-position: center;
    right: -250px;
    
    @media (max-width: 992px) {
        bottom: -100px;
        right: -175px;
    }
  }

  & > :nth-child(6) { /* Шестая карточка */
    top: -140px;
    background-image: url('https://i.ibb.co/DQjBnYd/Space-pc.jpg');
    left: -250px;
    background-size: cover;
    background-position: center;
    
    @media (max-width: 992px) {
        top: -100px;
        left: -175px;
    }
  }

  & > :nth-child(7) { /* Седьмая карточка */
    bottom: -200px;
    left: -200px;
    background-size: cover;
    background-position: center;
    background-image: url('https://i.ibb.co/FsGFz3x/2.jpg');
    
    @media (max-width: 992px) {
        bottom: -150px;
        left: -125px;
    }
  }

  & > :nth-child(8) { /* Восьмая карточка */
    bottom: -140px;
    background-image: url('https://i.ibb.co/8Njx7fs/1.jpg');
    left: -250px;
    background-size: cover;
    background-position: center;
    
    @media (max-width: 992px) {
        bottom: -100px;
        left: -175px;
    }
  }
  
`;

const Badge = styled.div`
  background-color: #00A3FF;
  border-radius: 10px;
  padding: 10px;
  font-family: 'Inter-Regular';
  font-size: 20px;
  color: #fff;
  width: fit-content;
  
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  font-family: 'Inter-Bold';
  font-weight: 700;
  font-size: 60px;
  color: #fff;
  
    @media (max-width: 992px) {
        font-size: 20px;
    }
`;

const ShortDescription = styled.div`
  font-family: 'Inter-Regular';
  font-size: 20px;
  color: #fff;
  width: 100%;
  
    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //box-shadow: 0 0 100px 0 #00A3FF;
`;

const QR = styled.div`
  width: 190px;
  height: 190px;
  background-image: url(${QRImage});
  border-radius: 10px;
    background-size: cover;
    background-position: center;
`;

const InfoSectionWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  
    @media (max-width: 992px) {
        display: none;
    }
`;


const FirstScreen = () => {
    // const [initialCursorPosition, setInitialCursorPosition] = useState({ x: '75%', y: '50%' });
    // const [cursorPosition, setCursorPosition] = useState({ x: '75%', y: '50%' });
    // const [offsetX, setOffsetX] = useState(0);
    // const [offsetY, setOffsetY] = useState(0);
    //
    // useEffect(() => {
    //     const handleMouseMove = (event) => {
    //         const offsetX = (event.clientX - initialCursorPosition.x) / 30; // Делим на 20 для уменьшения скорости смещения
    //         const offsetY = (event.clientY - initialCursorPosition.y) / 30; // Делим на 20 для уменьшения скорости смещения
    //         setCursorPosition({ x: event.clientX, y: event.clientY });
    //         setOffsetX(offsetX);
    //         setOffsetY(offsetY);
    //     };
    //
    //     const handleMouseDown = (event) => {
    //         setInitialCursorPosition({ x: event.clientX, y: event.clientY });
    //     };
    //
    //     window.addEventListener('mousemove', handleMouseMove);
    //     window.addEventListener('mousedown', handleMouseDown);
    //
    //     return () => {
    //         window.removeEventListener('mousemove', handleMouseMove);
    //         window.removeEventListener('mousedown', handleMouseDown);
    //     };
    // }, [initialCursorPosition]);

    return (
        <SectionWrapper paddingTop="50px">
            <Root>
                <FirstBlock>
                    <Badge>Middle</Badge>
                    <div>
                        <Title>Front - end</Title>
                        <Title>Developer</Title>
                    </div>
                    <ShortDescription>
                        Personal development, development of the company both in the global market and from within,
                        gaining new experience and skills, career advancement, stability, the ability to provide for oneself
                        and the family.
                    </ShortDescription>
                    <InfoSectionWrapper>
                        <QR/>
                        <ButtonWrapper>
                            <ReusableButton linkTo="https://www.linkedin.com/in/vlad-horba-074291219/" openPdfInNewTab={true} label="Linkedin" variant="outlined"/>
                            <ReusableButton as={'a'} href="mailto:krakenitsmydog@gmail.com" label="Email" variant="outlined"/>
                            <ReusableButton linkTo="https://github.com/bvffblvde" openPdfInNewTab={true} label="GitHub" variant="outlined"/>
                        </ButtonWrapper>
                    </InfoSectionWrapper>
                </FirstBlock>
                <SecondBlock>
                    {/*style={{ top: `calc(50% - ${offsetY}px)`, left: `calc(50% - ${offsetX}px)` }}*/}
                    <CardWrapper>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </CardWrapper>
                    <RoundIconButton/>
                </SecondBlock>
            </Root>
        </SectionWrapper>
    );
};

export default FirstScreen;