import React from 'react';
import SectionWrapper from '../../UI/SectionWrapper';
import TitleSection from "../../UI/TitleSection";
import Accordion from "../../UI/Accordion";
import styled from "styled-components";

const AccordionWrapper = styled.div`
  width: 75%;
  //margin-left: auto; /* Прижатие к правому краю */
  
    @media (max-width: 992px) {
        width: 100%;
    }

`;

const StackSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

const SoftSkillsContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
    @media (max-width: 992px) {
        display: none;
    }
`;

const TitleSkillSSection = styled.h3`
  font-size: 20px;
  font-family: 'Inter-Bold';
  color: #fff;
  font-weight: 700;
`;

const SkillsContent = styled.div`
  font-size: 20px;
  font-family: 'Inter-Regular';
  color: #fff;
  margin-top: 0;

  & > li {
    margin: 0;
    padding: 0;
  }
  
  & > ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`;

const JobExperience = () => {

    const items = [
        {
            title: 'Adbis',
            content: `<ul><li>Developing concepts for new applications, logical components, scaling the project in the future;</li><li>Develop, and maintain robust and scalable front-end applications using relevant programming languages and frameworks;</li><li> Refactoring and optimization of the functionality of existing company interfaces;</li><li>Write adaptive websites of varying complexity according to approved design layouts(shops, landing pages, corporate websites, etc.);</li><li>Improvement of existing websites; edits to projects (mobile adaptation, cross-browser compatibility, page speed, animations);</li><li>Creation of inclusive websites from scratch, implementation of functionality for disabled people according to WCAG requirements. Changing the theme of the site, manipulating the change in font size;</li><li>Implementation of Google analytics, including full tracking of user actions;</li><li>Implementation of StoryBook in some commercial projects;</li></ul>`
        },
        {
            title: 'Leo-Source',
            content: `<ul><li>Page redesign implementation;</li><li>
Refactoring of the current functionality; </li><li>
Review and debug code for quality, bugs, security issues, scalability, and performance during development and production; </li><li>
Testing with Jest; </li><li>
Development of new and support for existing functionality in React / TypeScript, layout; </li><li>
Implementation of complex interface elements that can be reused; </li><li>
Convert projects and wireframes to high quality code; </li><li>
Optimizing components for maximum performance on a wide range of web-enabled devices and browsers; </li><li>
Responsive design with Bootstrap/Material Ui & other; </li><li>
Creating Plugins / Scripts for the convenience of working with UpWork / GitHub; </li><li>
New employee code review; </li><li>
Document application changes and develop updates; </li><li>
Using mobile-first technology when building a landing page;</li></ul>`
        },
        {
            title: 'Freelance as Web Designer',
            content: `<ul><li>Creation of templates for web pages and sites in general; </li><li>
Page design development; </li><li>
Development of profile headers and the profiles themselves for personal and
Commercial pages in social networks; </li><li>
Creation of illustrations, thinking over the logic of their location; </li><li>
Understanding how the user interface works UX; </li><li>
Possession of graphic editors (Adobe Photoshop, Illustrator); </li><li>
Understanding the process of creating 3D graphics (Blender, Solid);</li></ul>`,
        },
    ];

    const skills = [
        {
            title: 'Skills',
            content: `<ul><li>Knowledge of testing theory;</li><li>Test writing skills;</li><li>Platform: Windows/Linux, MacOS;</li><li>Source control: basic familiarity with
Git;</li><li>Adaptability;</li><li>Foreign language: English – intermediate level;</li><li>Attention to detail;</li><li>Self-motivation;</li><li>Leadership;</li><li>Conflict resolution;</li></ul>`
        }
    ];

    return (
        <SectionWrapper>
            <TitleSection title="Job Experience"/>
            <StackSection>
                <SoftSkillsContainer>
                   <TitleSkillSSection>{skills[0].title}</TitleSkillSSection>
                    <SkillsContent dangerouslySetInnerHTML={{__html: skills[0].content}}/>

                </SoftSkillsContainer>
                <AccordionWrapper>
                    <Accordion items={items}/>
                </AccordionWrapper>
            </StackSection>
        </SectionWrapper>
    );
};

export default JobExperience;