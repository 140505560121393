import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: ${({ hovered }) => (hovered ? '#fff' : '#ECECEC')};
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 50px 0 #00A3FF;
  }
  
  @media (max-width: 992px) {
    width: 80px;
    height: 80px;
  }
`;

const Image = styled.img`
  transition: all 0.5s ease;
  opacity: ${({ hovered }) => (hovered ? 1 : 0.5)};
  filter: ${({ hovered }) => (hovered ? 'none' : 'grayscale(100%)')}; /* Добавьте точку с запятой здесь */
  width: 50px;
  height: 50px;
  object-fit: contain;
  
  @media (max-width: 992px) {
    width: 40px;
    height: 40px;
    filter: none;
    opacity: 1;
  }
`;


const Card = ({ img, title }) => {
    const [hovered, setHovered] = React.useState(false);

    return (
        <CardWrapper
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            hovered={hovered}
        >
            <Image src={img} alt={title} hovered={hovered}/>
        </CardWrapper>
    );
};

export default Card;
