import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../../assets/Icons/arrow-accordion.svg';

const ButtonLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 6px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-family: 'Inter-Regular';
  -webkit-tap-highlight-color: transparent;
  font-size: 20px;
  transition: all 0.5s ease;
  
  & > svg {
    rotate: 270deg;
  }

  &:hover {
    background-color: #00A3FF;
    & > svg {
      transform: rotate(45deg);
    }
  }
  
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ButtonTitle = styled.span`
  margin-right: 32px;
  
  @media (max-width: 768px) {
    margin-right: 16px;
  }
`;

const ArrowIconStyled = styled(ArrowIcon)`
  width: 20px;
  height: 20px;
  transition: transform 0.5s ease; /* Добавлено анимацию для поворота */
`;

const ButtonWithArrow = ({ title, to }) => {
    return (
        <ButtonLink to={to} target="_blank" rel="noopener noreferrer">
            <ButtonTitle>{title}</ButtonTitle>
            <ArrowIconStyled />
        </ButtonLink>
    );
};

export default ButtonWithArrow;

