import React from 'react';
import styled from 'styled-components';
import StyledButton from "../../UI/StyledButton";
import FloatingButton from "../../UI/FloatingButton";

// Стили для хедера
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 40px 100px;
  z-index: 1000;

  @media (min-width: 1600px) {
    padding: 40px 15%;
  }
  
  @media (max-width: 992px) {
    padding: 20px;
  }
`;

const Title = styled.span`
  color: white;
  font-family: 'Inter-Bold', serif;
  font-size: 20px;
  white-space: pre-line;
  
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

interface Props {
    name: string;
    surname: string;
}

const Header: React.FC<Props> = ({name, surname}) => {
    return (
        <HeaderContainer>
            <Box>
                <Title>{name}</Title>
                <Title>{surname}</Title>
            </Box>
            <FloatingButton />
            <StyledButton linkTo="https://drive.google.com/file/d/1Jd1nK0FG0-WkJl31PybvdsNRPVurrnTu/view?usp=drive_link" openPdfInNewTab={true} variant="default" label="Download CV" />
        </HeaderContainer>
    );
}

export default Header;

