import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  font-family: 'Inter-Bold';
  font-size: 58px;
  color: #fff;

  @media (max-width: 992px) {
    font-size: 20px;
  }
`;

const TitleSection = ({title}) => {
    return <Title>{title}</Title>;
};

export default TitleSection;