import React from 'react';
import styled from 'styled-components';

const RoundButton = styled.button`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.5s ease;
  
  &:hover {
    background-color: #00a3ff;
    border-color: #00a3ff;
    box-shadow: 0 0 100px 0 #00a3ff;
  }
  
  @media (max-width: 992px) {
    width: 100px;
    height: 100px;
    font-size: 14px;
  }
`;

const GoTo = styled.span`
  margin-bottom: 5px;
`;

const Portfolio = styled.span`
  margin-top: 5px;
`;

const RoundIconButton = () => {

    const handleClick = () => {

        const portfolioSection = document.getElementById('portfolio');
        if (portfolioSection) {
            const offset = 100; // Смещение на 70px вниз
            const topPos = portfolioSection.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
                top: topPos + offset,
                behavior: 'smooth'
            });
        }
    };

    return (
        <RoundButton onClick={handleClick}>
            <GoTo>Go To</GoTo>
            <Portfolio>Portfolio</Portfolio>
        </RoundButton>
    );
};

export default RoundIconButton;

