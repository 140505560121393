import React from 'react';
import styled from 'styled-components';

const Badge = styled.div`
  background-color: rgba(255, 255, 255, 0.4); /* Цвет бейджа с прозрачностью */
  border-radius: 6px;
  padding: 10px;
  width: fit-content;
`;

const Text = styled.span`
  font-family: 'Inter-Regular';
  font-size: 20px;
  color: rgba(255, 255, 255, 1); /* Цвет текста всегда белый */
  
    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

const Tag = ({ title }) => {
    return (
        <Badge>
            <Text>
                {title}
            </Text>
        </Badge>
    );
};

export default Tag;

