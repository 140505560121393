import React from 'react';
import SectionWrapper from '../../UI/SectionWrapper';
import TitleSection from "../../UI/TitleSection";
import styled from "styled-components";
import Tag from "../../UI/Tag";
import TurystykaDesktop from "../../../assets/MockUps/turystyka-desktop.png";
import FundacjaDesktop from "../../../assets/MockUps/fundacja-redesign.png";
import SpaceDesktop from "../../../assets/MockUps/space-mockup.png";
import WoodPlusDesktop from "../../../assets/MockUps/woodplus-mockup.png";
import WislaDesktop from "../../../assets/MockUps/wisla-mockup.png";
import ArrowButton from "../../UI/ArrowButton";

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Два элемента в ряду */
  gap: 40px; /* Промежуток между элементами */
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr; /* Один элемент в ряду */
  }
`;

const ProjectItem = styled.div`
  position: relative; /* Позиционирование для контейнера проекта */
  //margin-bottom: 20px;
  border-radius: 10px;
  padding: 32px 20px 20px 20px;
  background-color: ${({backgroundColor}) => backgroundColor}; /* Индивидуальный цвет фона */
  
  @media (max-width: 992px) {
    padding: 10px;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 50px;
  
    @media (max-width: 992px) {
        margin-bottom: 20px;
    }
`;

const ProjectTags = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const ProjectDescription = styled.p`
  margin-top: 10px;
  font-size: 20px;
  font-family: 'Inter-Regular';
  color: white;
  
    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center; /* Выравнивание изображений по центру */
`;

const Portfolio = () => {

    const projects = [
        {
            id: 1,
            image: TurystykaDesktop,
            tags: ['Travel site', 'Blog', 'With Wordpress API', 'Redesign', 'WCAG', 'In Process'],
            description: '"Turystyka BEZ FILTRÓW" is a brand that started as a hobbyist travel blog, with time growing into a significant initiative on the scale of Bydgoszcz and the region. Today, it includes a website with regular articles on tourism and culture (including series: "Bydgoszcz estates WITHOUT FILTERS", "Bydgoszcz through the keyhole")',
            link: 'https://turystykabezfiltrow.com/',
            backgroundColor: '#9AB6FFCC',
            buttonText: 'Visit site'
        },
        {
            id: 2,
            image: FundacjaDesktop,
            tags: ['Blog', 'With Wordpress API', 'Redesign', 'WCAG'],
            description: 'The Foundation for the Promotion of Culture and Tourism "By the River" is a non-governmental organization, registered on 31.07.2020. The foundation is formed by a three-member international team of funders, at the same time making up its Board of Directors. They are: Piotr Weckwerth (president), and Serhii Zinchenko and Anton Karabach (vice-presidents). Inna Yaremchuk works with the Foundation on a permanent basis.\n',
            link: 'https://fundacjanadrzeka.com/',
            backgroundColor: '#78BFCECC',
            buttonText: 'Visit site'

        },
        {
            id: 3,
            image: SpaceDesktop,
            tags: ['Clinic', 'Commercial', 'Redesign', 'In Process'],
            description: 'The Space Clinic is a medical center that offers medical and preventive services. It was necessary to redesign the website to provide more detailed information about the services offered, additional details about the specialists working there, and pricing information.',
            link: 'https://space-clinic-ua.pages.dev/',
            backgroundColor: '#0ABAB5',
            buttonText: 'Visit DEV site'
        },
        {
            id: 4,
            image: WoodPlusDesktop,
            tags: ['Landing-Page', 'Commercial'],
            description: 'The purpose of this project was to showcase the company\'s activities and the materials they work with, essentially serving as a business card. Additionally, it was important to present a portfolio of their work.',
            link: 'https://woodplus.com.ua/',
            backgroundColor: '#1E1E1E',
            buttonText: 'Visit site'
        },
        {
            id: 5,
            image: WislaDesktop,
            tags: ['Landing-Page', 'Project Site'],
            description: 'The main goal of this project was to demonstrate the severity of the drought\'s impact on the farming community of Kujawa and to propose potential solutions to address this issue.\n' +
                'The project was collaboratively prepared by an independent team of experts from various industries and institutions, appointed by the Nadwislańskie Związek Pracodawców Lewiatan.',
            link: 'https://wisladlakujaw.pl/',
            backgroundColor: '#b7d8e5',
            buttonText: 'Visit site'
        },
    ];

    return (
        <SectionWrapper id="portfolio" >
            <TitleSection title="Portfolio"/>
            <ProjectsGrid>
                {projects.map(project => (
                    <ProjectItem key={project.id} backgroundColor={project.backgroundColor}>
                        <ImageWrapper>
                            <ProjectImage src={project.image} alt={`Project ${project.id}`}/>
                        </ImageWrapper>
                        <ProjectTags>
                            {project.tags.map(tag => (
                                <Tag key={tag} title={tag}/>
                            ))}
                        </ProjectTags>
                        <ProjectDescription>{project.description}</ProjectDescription>
                        <ArrowButton title={project.buttonText} to={project.link}/>
                    </ProjectItem>
                ))}
            </ProjectsGrid>
        </SectionWrapper>
    );
};

export default Portfolio;

